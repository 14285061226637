import { LocationStrategy } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthToken } from '../models/token';
import { TokenService } from './token.service';

/**
 * Common authentication service.
 * Should be used to as an interlayer between UI Components and Auth Strategy.
 */
@Injectable()
export class AuthService {
  constructor(
    protected tokenService: TokenService,
    private keycloakService: KeycloakService,
    private location: LocationStrategy,
    private injector: Injector
  ) {}

  public get router(): Router {
    return this.injector.get(Router);
  }
  getToken(): Observable<AuthToken> {
    return this.tokenService.get();
  }

  setToken(data): Promise<any> {
    return this.tokenService.set(new AuthToken(data)).toPromise();
  }

  isAuthenticated(): Observable<boolean> {
    return this.getToken().pipe(map((token: AuthToken) => token.isValid()));
  }

  onTokenChange(): Observable<AuthToken> {
    return this.tokenService.tokenChange();
  }

  logout() {
    this.tokenService.clear();
    let env = '';
    let redirectURI;
    if (location.origin.indexOf('devint') > -1) {
      env = 'devint';
    } else if (
      location.origin.indexOf('dev') > -1 ||
      location.origin.indexOf('10.') > -1 ||
      location.origin.indexOf('localhost') > -1
    ) {
      env = 'dev';
    }
    if (env === 'devint' || env === 'dev') {
      redirectURI = `https://login-preview.ienergycloud.solutions/login/signout?fromURI=https://decisionspace365.io?env=${env}`;
    } else {
      redirectURI = `https://login.ienergycloud.solutions/login/signout?fromURI=https://decisionspace365.io`;
    }
    this.keycloakService.logout(redirectURI).then(() => {});
  }

}
