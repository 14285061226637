<div [ds365Loader]="loading">
  <ds365-stepper (selectionChange)="onStepChanged($event)">
    <div stepperHeader class="container-fluid px-0">
      <ds365-banner [fixed]="true" background="secondary" text="white" [grow]="true">
        <div class="row justify-content-start flex-grow-1">
          <div class="col-md-4 align-self-center">
            <h3 class="font-weight-light ml-lg-5 text-center text-md-left mb-0">
              <span class="ml-1">
                Getting Started
              </span>
            </h3>
          </div>
          <div class="col-md-6 align-self-center pb-3 pb-md-0">
            <ds365-stepper-navigation-bar> </ds365-stepper-navigation-bar>
          </div>
        </div>
      </ds365-banner>
    </div>
    <ds365-step>
      <ng-template ds365StepIndicator>
        <ds365-icon svgName="trial-nav" [status]="iconStatus(0)" [bg]="iconBg(0)"></ds365-icon>
      </ng-template>
      <div class="row">
        <div class="col-md-10 col-sm-12 offset-md-2">
          <div class="row">
            <div class="col-12">
              <h3 class="font-weight-light page-title">Start Free Trial</h3>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-12">
              <p>
                Using an application is few clicks away by using the free trial feature.
              </p>
              <p>
                See how in this quick video:
              </p>
            </div>
          </div>
          <div class="row justify-content-center mb-5">
            <div class="col-12">
              <ds365-video-tile
                *ngIf="videosMap"
                [video]="videosMap.get('try-before-you-buy')"
                [videoOnly]="true"
                width="500px"
                (playVideo)="onPlay(videosMap.get('try-before-you-buy'))"
              ></ds365-video-tile>
            </div>
          </div>
          <div class="row pt-3 mb-2">
            <div class="col-12">
              <p>
                Note: You can later access this video at <a href="learn/tutorials">Tutorials</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ds365-step>
    <ds365-step>
      <ng-template ds365StepIndicator>
        <ds365-icon svgName="subscription-nav" [status]="iconStatus(1)" [bg]="iconBg(1)"></ds365-icon>
      </ng-template>
      <div class="row">
        <div class="col-md-10 col-sm-12 offset-md-2">
          <div class="row">
            <div class="col-12">
              <h3 class="font-weight-light page-title">Purchase a Subscription</h3>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <p>
                Want to make a subscription? Choose the closest region and subscription type.
              </p>
              <p>
                Watch this video to know more:
              </p>
              <p>
                * Some regions do not offer a monthly subscription.
              </p>
            </div>
          </div>
          <div class="row justify-content-center mb-5">
            <div class="col-12">
              <ds365-video-tile
                *ngIf="videosMap"
                [video]="videosMap.get('how-to-purchase-subscription')"
                [videoOnly]="true"
                width="500px"
                (playVideo)="onPlay(videosMap.get('how-to-purchase-subscription'))"
              ></ds365-video-tile>
            </div>
          </div>
          <div class="row pt-3 mb-2">
            <div class="col-12">
              <p>
                Note: You can later access this video at <a href="learn/tutorials">Tutorials</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ds365-step>
    <ds365-step>
      <ng-template ds365StepIndicator>
        <ds365-icon svgName="it-config-nav" [status]="iconStatus(2)" [bg]="iconBg(2)"></ds365-icon>
      </ng-template>
      <div class="row">
        <div class="col-md-10 col-sm-12 offset-md-2">
          <div class="row">
            <div class="col-12">
              <h3 class="font-weight-light page-title">IT Configurations</h3>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <p>
                Are you experiencing issues when connecting to Workspace Client?
              </p>
              <p>
                Please access the documentation below to work with your IT Team.
              </p>
              <span>
                URL: 
                <a class="link-it-configurations" 
                  target="_blank"
                  href="https://docs.aws.amazon.com/workspaces/latest/adminguide/workspaces-port-requirements.html#client-application-ports">
                  https://docs.aws.amazon.com/workspaces/latest/adminguide/workspaces-port-requirements.html#client-application-ports
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </ds365-step>
    <div stepperFooter class="container-fluid px-0">
      <ds365-banner background="secondary" text="white">
        <div class="row row-height m-0 flex-grow-1">
          <div class="col-md-12 align-self-center">
            <div class="d-flex justify-content-around wizard-button" banner-btns>
              <div class="col-md-2"></div>
              <div class="col-md-3">
                <button
                  ds365Button
                  status="primary"
                  id="wizardPrev"
                  class="mr-1"
                  (keydown.enter)="$event.preventDefault()"
                  ds365StepperPrevious
                  *ngIf="navigations.back"
                >
                  <ds365-icon svgName="arrow-left" svgSize="10" status="white" [svgStyle]="{ margin: '0 12px 0 -12px' }">
                  </ds365-icon>
                  Back
                </button>
              </div>
              <div class="col-md-3">
                <button
                  ds365Button
                  status="primary"
                  id="wizardNext"
                  class="mr-1"
                  (keydown.enter)="$event.preventDefault()"
                  ds365StepperNext
                  *ngIf="navigations.next"
                >
                  Next
                  <ds365-icon svgName="arrow_right" svgSize="10" status="white" [svgStyle]="{ margin: '0 -12px 0 12px' }">
                  </ds365-icon>
                </button>
              </div>
              <div class="col-md-3">
                <button
                  ds365Button
                  status="primary"
                  class="mr-1"
                  (click)="activeModal.close('close')"
                >
                  Close
                </button>
              </div>
              <div class="col-md-1"></div>
            </div>
          </div>
        </div>
      </ds365-banner>
    </div>
  </ds365-stepper>
</div>
