<header class="sticky-header">
  <nav class="navbar navbar-expand-lg navbar-light p-0 bg-primary">
    <div>
      <a class="navbar-brand" href="javascript:void(0)">
        <a href="/welcome">
          <img class="logo mb-1" [src]="application?.configs?.logo.path" />
        </a>
        <span (click)="toNewUI()" class="beta ml-1" *ngIf="betaUrl && !isSignupPage">
          Switch to new site
        </span>
      </a>
    </div>
    <div *ngIf="isSignupPage" class="w-100">
      <div class="float-right mr-4">
        <!-- <a href="#" class="mx-2">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-bell" fill="#fff"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2z" />
            <path fill-rule="evenodd"
              d="M8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
          </svg>
        </a>
        <a href="" class="mx-2">
          <ds365-icon svgName="support" svgSize="20" status="white"></ds365-icon>
        </a> -->
        <!-- <a href="" class="mx-2">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-person-fill" fill="#fff"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          </svg>
        </a> -->
      </div>
    </div>
    <button *ngIf="!isSignupPage" (click)="navBarCollapsed = !navBarCollapsed" class="navbar-toggler" type="button"
      data-toggle="collapse" data-target="#ds365-navbars-wrapper" aria-controls="ds365-navbars-wrapper"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div *ngIf="!isSignupPage" [ngbCollapse]="navBarCollapsed"
      class="collapse navbar-collapse flex-row-reverse align-self-start bg-primary" id="ds365-navbars-wrapper">
      <ds365-navbars [items]="navItems"  [count]="newCounts > 0 ? newCounts : ''"
        (displayNotifications)="onDisplayNotifications($event)"></ds365-navbars>
    </div>
  </nav>
</header>

<ds365-notifications
(notificationClose)="isShowNotifications = $event"
(notificationHided)="onNotificationHide($event)"
[notifications]="notifications"
[user]="user"
*ngIf="isShowNotifications"
>
</ds365-notifications>
