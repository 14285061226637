<ul id="ds365-navbars" class="navbar-nav font-weight-light" *ngIf="isNavBar && authService.isAuthenticated() | async">
  <li *ngFor="let item of items" class="nav-item px-3 px-xl-0 h-100">
    <ds365-dropdown [item]="item">
      <span label>{{ item.title }}</span>
    </ds365-dropdown>
  </li>
  <!-- <li class="nav-item">
    <a (click)="doNothing($event)" class="nav-link">
      <ds365-icon svgName="notification" svgSize="20" status="white"></ds365-icon>
    </a>
  </li>
  <li class="nav-item">
    <a [routerLink]="['/web/support/ticket']" class="nav-link">
      <ds365-icon svgName="support" svgSize="20" status="white"></ds365-icon>
    </a>
  </li> -->
  <li class="nav-item mr-3">
    <div class="d-flex justify-content-xl-around justify-content-between">
      <ds365-dropdown class="float-left" [item]="userProfileData" [caret]="true" status="white">
        <span class="font-weight-light fs-18 text-white" label>{{
          userProfileData.title | truncate: ['14', '...']
        }}
      </span>
      </ds365-dropdown>
    </div>
  </li>
  <li class="nav-item">
    <a
    (click)="onShowNotifications()"
    style="position: relative;"
      class="nav-link mt-1 mr-1"
      ngbTooltip="Notifications" 
    >
    <ds365-icon svgName="notification" status="white" svgSize="20"></ds365-icon>
    <span
        class="badge badge-danger font-weight-bold notification-badge rounded-circle mx-1 pt-1 my-1"
        *ngIf="count"
        >{{ count }}</span
      >
    </a>
  </li>
   <li class="nav-item">
    <a class="nav-link mt-1 mr-2 m-w-80" [routerLink]="['/shopping/cart']">
      <ds365-icon svgName="shopping-cart" svgSize="20" status="white"></ds365-icon>
      <span class="text-white ml-2">({{cartCount}})</span>
    </a>
  </li>
</ul>
